import { useState, forwardRef, useImperativeHandle } from "react";
import "./index.css";

const JXT_TOAST = forwardRef((props, ref) => {
  // `useImperativeHandle` 用来暴露方法
  useImperativeHandle(ref, () => ({
    showToast,
    hideToast,
  }));

  let TimerDuration = null; //页面显示的定时器
  let Timer = null; //图标旋转的定时器

  //具体展示的值
  const [isShow, setIsShow] = useState(false); //控制展示
  const [showIcon, setShowIcon] = useState("success"); //图标
  const [imageRotate, setImageRotate] = useState(0); //图片旋转角度
  const [message, setMessage] = useState("成功~"); //文本
  let durationTime = 2000; //隐藏时间

  /**
   * 展示toast
   * @param {Object} param 参数
   * @param {string} param.value 文本
   * @param {string} param.icon 图标
   * @param {number} param.duration 隐藏书剑
   */
  const showToast = ({
    value = "成功~",
    icon = "success",
    duration = 2000,
  }) => {
    //先执行隐藏，防止多次触发
    hideToast();
    //具体展示的值
    setMessage(value); //文本
    setImageRotate(0); //图片旋转角度
    setShowIcon(icon); //图标
    if (duration < 0) {
      durationTime = 0;
    } else if (duration > 600000) {
      //不超过十分钟
      durationTime = 600000;
    } else {
      durationTime = duration;
    }
    if (icon === "loading") {
      setMessage("加载中...");
      //加载旋转动画
      Timer = setInterval(function () {
        setImageRotate(imageRotate + 1); // 每次增加1度
      }, 10); // 间隔时间，单位为毫秒
    } else {
      //非Loading图标时
      //定时关闭
      TimerDuration = setTimeout(function () {
        //隐藏
        hideToast();
      }, durationTime);
    }
    //设置显示
    setIsShow(true);
  };

  /**
   * 隐藏Toast
   */
  const hideToast = () => {
    clearTimeout(TimerDuration); // 停止定时器
    clearInterval(Timer); // 停止定时器
    setIsShow(false); //控制展示
    setMessage("成功~"); //文本
    setImageRotate(0); //图片旋转角度
    setShowIcon("success"); //图标
    durationTime = 2000; //隐藏时间
  };

  /**
   * 按条件渲染图标
   */
  function RenderIcon() {
    if (showIcon === "success") {
      return (
        <svg
          t="1722428921914"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1217"
          width="64"
          height="64"
        >
          <path
            d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
            fill="#52C41A"
            p-id="1218"
          ></path>
          <path
            d="M178.614857 557.860571a42.496 42.496 0 0 1 60.123429-60.050285l85.942857 87.625143a42.496 42.496 0 0 1-60.050286 60.123428L178.614857 557.860571z m561.005714-250.148571a42.496 42.496 0 1 1 65.097143 54.637714L394.459429 725.577143a42.496 42.496 0 0 1-65.097143-54.637714l410.112-363.373715z"
            fill="#FFFFFF"
            p-id="1219"
          ></path>
        </svg>
      );
    }
    if (showIcon === "prompt") {
      return (
        <svg
          t="1722429036621"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1380"
          width="64"
          height="64"
        >
          <path
            d="M512 0a512 512 0 0 0-512 512 512 512 0 0 0 512 512 512 512 0 0 0 512-512 512 512 0 0 0-512-512z"
            fill="#05CFA4"
            p-id="1381"
          ></path>
          <path
            d="M541.769143 393.142857a50.322286 50.322286 0 1 1 0-100.571428 50.322286 50.322286 0 0 1 0 100.571428zM369.810286 542.061714c-24.502857 20.333714 67.437714-95.085714 100.352-115.565714 32.914286-20.406857 91.209143-4.973714 83.163428 42.276571-8.045714 47.323429-59.172571 220.013714-70.802285 261.12-11.702857 41.106286 71.68-38.107429 88.137142-48.128 16.457143-9.947429-62.902857 93.184-100.498285 114.907429-37.595429 21.723429-93.769143-7.68-82.651429-47.030857 11.190857-39.424 52.662857-186.514286 69.632-244.882286 16.969143-58.441143-62.902857 16.969143-87.332571 37.302857z"
            fill="#FFFFFF"
            p-id="1382"
          ></path>
        </svg>
      );
    }
    if (showIcon === "warning") {
      return (
        <svg
          t="1722429061115"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1543"
          width="64"
          height="64"
        >
          <path
            d="M492.763429 0C220.745143 0 0 218.477714 0 487.643429c0 269.165714 220.745143 487.570286 492.763429 487.570285 272.018286 0 492.763429-218.404571 492.763428-487.570285S764.781714 0 492.763429 0z m-49.298286 682.642286c0-26.916571 21.869714-48.713143 48.786286-48.713143h1.024a48.786286 48.786286 0 0 1 0 97.499428H492.251429a48.786286 48.786286 0 0 1-48.786286-48.786285z m0-195.510857V293.083429a49.298286 49.298286 0 1 1 98.523428 0V487.131429a49.298286 49.298286 0 1 1-98.523428 0z"
            fill="#FAAD14"
            p-id="1544"
          ></path>
        </svg>
      );
    }
    if (showIcon === "error") {
      return (
        <svg
          t="1722429085328"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1705"
          width="64"
          height="64"
        >
          <path
            d="M512 0a512 512 0 0 0-512 512 512 512 0 0 0 512 512 512 512 0 0 0 512-512 512 512 0 0 0-512-512z"
            fill="#FD6B6D"
            p-id="1706"
          ></path>
          <path
            d="M513.755429 565.540571L359.277714 720.018286a39.058286 39.058286 0 0 1-55.296-0.073143 39.277714 39.277714 0 0 1 0.073143-55.442286l154.331429-154.331428-155.062857-155.136a36.571429 36.571429 0 0 1 51.712-51.785143l365.714285 365.714285a36.571429 36.571429 0 1 1-51.785143 51.785143L513.755429 565.540571z m157.549714-262.582857a35.254857 35.254857 0 1 1 49.737143 49.737143l-106.057143 108.982857a35.254857 35.254857 0 1 1-49.883429-49.810285l106.203429-108.982858z"
            fill="#FFFFFF"
            p-id="1707"
          ></path>
        </svg>
      );
    }
    if (showIcon === "loading") {
      return (
        <svg
          style={{ transform: `rotate(${imageRotate}deg)` }}
          t="1722429394298"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="17731"
          width="64"
          height="64"
        >
          <path
            d="M511.5904 102.4C736.0512 102.4 921.6 287.9488 921.6 512c0 224.4096-185.5488 409.6-409.6 409.6-224.0512 0-409.6-185.1904-409.6-409.6 0-224.0512 185.1904-409.6 409.1904-409.6zM512 266.24A245.76 245.76 0 0 0 266.24 512h65.536A180.224 180.224 0 1 1 512 692.224v65.536a245.76 245.76 0 1 0 0-491.52z"
            fill="#0088FF"
            p-id="17732"
          ></path>
        </svg>
      );
    }
  }

  return (
    isShow && (
      <div className="toast-div">
        <div className="toast">
          <RenderIcon />
          <p className="txt">{message}</p>
        </div>
      </div>
    )
  );
});

export default JXT_TOAST;
