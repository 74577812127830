import axios from "axios";
//获取配置文件
import { JXT_Configs } from "./config";
import { stringToSha512AndOffset, concatenateData } from "./hash";
const config_request = JXT_Configs.request;

/**
 * 发起请求的函数
 * @param {Object} params - 请求参数
 * @param {string} params.method - 请求方法，如 'GET' 或 'POST'
 * @param {string} params.url - 请求的 URL
 * @param {File|null} params.file - 要上传的文件（可选）
 * @param {string|null} params.fileKey - 文件的 key（可选）
 * @param {Object} params.data - 请求的数据
 * @param {Function} params.onSuccess - 请求成功的回调函数
 * @param {Function} params.onFail - 请求失败的回调函数
 * @param {Function} params.complete - 请求完成的回调函数
 */
export function request({
  method = "GET",
  url,
  file = null,
  fileKey = null,
  data = {},
  header = {},
  onSuccess = () => {},
  onFail = () => {},
  complete = () => {},
}) {
  // 创建请求数据
  let requestData;

  // 创建 FormData 对象
  const formData = new FormData();
  if (file != null) {
    method = "POST";
    formData.append(fileKey, file);
    // 如果有其他数据，也添加到 FormData 中
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    requestData = formData;
  } else {
    requestData = data;
  }

  //基础url
  let baseUrl = config_request.url;
  if (url.includes("http://") || url.includes("https://")) {
    baseUrl = "";
  }

  //头信息
  const headers = header;
  if (!("Content-Type" in headers)) {
    headers["Content-Type"] =
      method === "GET" ? "application/json" : "multipart/form-data";
  }
  header["token"] = config_request.token;
  headers["Fingerprint"] = stringToSha512AndOffset(
    concatenateData(requestData)
  ); //添加指纹

  // 创建axios的实例
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 60000,
    headers: headers,
    withCredentials: false, // 确保请求中不带上 Cookie
  });

  // 发送请求
  instance
    .request({
      method: method,
      url: url,
      params: method.toUpperCase() === "GET" ? requestData : undefined, // 使用 params 来传递 GET 请求的参数
      data: method.toUpperCase() === "POST" ? requestData : undefined, // 使用 data 来传递 POST 请求的数据
    })
    .then((response) => {
      if (response.status === 200) {
        // 处理成功的响应
        if (typeof onSuccess === "function") {
          onSuccess(response.data);
        }
        if (typeof complete === "function") {
          complete(response.data);
        }
      } else {
        if (typeof onFail === "function") {
          onFail("请求异常！");
        }
        if (typeof complete === "function") {
          complete(response);
        }
      }
    })
    .catch((error) => {
      // 处理错误
      if (typeof onFail === "function") {
        // onFail(error.response ? error.response.data : error.message);
        onFail("请求错误！");
      }
      if (typeof complete === "function") {
        complete(error.response ? error.response : error.message);
      }
    });
}
export default request;
