const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^[a-zA-Z0-9!]+$/;
const numberRegex = /^[0-9]+$/;

/**
 * 校验电子邮件地址
 * @param {*} email
 * @returns
 */
function isValidEmail(email) {
  return emailRegex.test(email);
}
/**
 * 验证密码
 * @param {*} password
 */
function isValidPassword(password) {
  return passwordRegex.test(password);
}
/**
 * 校验是否纯数字
 * @param {*} numText
 * @returns
 */
function isValidNumber(numText) {
  return numberRegex.test(numText);
}

export { isValidEmail, isValidPassword, isValidNumber };
