/**
 * 设置Cookie
 * @param key 键
 * @param value 值
 * @param expiredays 过期天数
 */
export const setCookie = (key, value, expiredays = 7) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = `${key}=${encodeURIComponent(
    value
  )};expires=${exdate.toUTCString()};path=/`;
};

/**
 * 读取Cookie
 * @param key 键值
 */
export const getCookie = (key) => {
  const name = `${key}=`;
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(name) === 0) {
      return decodeURIComponent(cookie.substring(name.length, cookie.length));
    }
  }
  return null;
};

/**
 * 检查Cookie是否存在
 */
export const hasCookie = (key) => {
  const cookie = getCookie(key);
  return cookie !== null;
};

/**
 * 删除Cookie
 * @param key 键值
 */
export const deleteCookie = (key) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() - 1);
  document.cookie = `${key}=;expires=${exdate.toUTCString()};path=/`;
};
