import "./loading-page.css";

export default function LoadingPage() {
  return (
    <div className="loading">
      {/* From Uiverse.io by jeremyssocial */}
      <div className="hacker-loader">
        <div className="loader-text">
          <span data-text="内容加载中..." className="text-glitch">
            内容加载中...
          </span>
        </div>
        <div className="loader-bar">
          <div className="bar-fill"></div>
          <div className="bar-glitch"></div>
        </div>
        <div className="particles">
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
        </div>
      </div>
    </div>
  );
}
