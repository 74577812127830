import React, { useState, useEffect, useRef } from "react";
import Head from "../../components/head/head";
import "./home.css";
import { useNavigate, Outlet } from "react-router-dom";
import { hasCookie, getCookie, setCookie } from "../../utils/cookie";

//导航列表数据
let navList = [
  {
    id: 1,
    title: "首页数据",
    icon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M44 44V20L24 4L4 20L4 44H16V26H32V44H44Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="bevel"/><path d="M24 44V34" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/></svg>`,
    selectIcon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M44 44V20L24 4L4 20L4 44H16V26H32V44H44Z" fill="#f15a29" stroke="#f15a29" stroke-width="4" stroke-linejoin="bevel"/><path d="M24 44V34" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/></svg>`,
  },
  {
    id: 2,
    title: "文章管理",
    icon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39 4H11C9.89543 4 9 4.89543 9 6V42C9 43.1046 9.89543 44 11 44H39C40.1046 44 41 43.1046 41 42V6C41 4.89543 40.1046 4 39 4Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M17 30L31 30" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M17 36H24" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><rect x="17" y="12" width="14" height="10" fill="none" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/></svg>`,
    selectIcon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39 4H11C9.89543 4 9 4.89543 9 6V42C9 43.1046 9.89543 44 11 44H39C40.1046 44 41 43.1046 41 42V6C41 4.89543 40.1046 4 39 4Z" fill="#f15a29" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M17 30L31 30" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M17 36H24" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><rect x="17" y="12" width="14" height="10" fill="#f15a29" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/></svg>`,
  },
  {
    id: 3,
    title: "用户管理",
    icon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 20C27.866 20 31 16.866 31 13C31 9.13401 27.866 6 24 6C20.134 6 17 9.13401 17 13C17 16.866 20.134 20 24 20Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M12 7.25488C10.1865 8.51983 9 10.6214 9 13.0002C9 15.5465 10.3596 17.7753 12.3924 19.0002" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M36 7.25488C37.8135 8.51983 39 10.6214 39 13.0002C39 15.3789 37.8135 17.4806 36 18.7455" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M12 40V42H36V40C36 36.2725 36 34.4087 35.391 32.9385C34.5791 30.9783 33.0217 29.4209 31.0615 28.609C29.5913 28 27.7275 28 24 28C20.2725 28 18.4087 28 16.9385 28.609C14.9783 29.4209 13.4209 30.9783 12.609 32.9385C12 34.4087 12 36.2725 12 40Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M43.9999 42.0001V40.8001C43.9999 36.3197 43.9999 34.0795 43.128 32.3682C42.361 30.8629 41.1371 29.6391 39.6318 28.8721" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M4.00009 42.0001V40.8001C4.00009 36.3197 4.00009 34.0795 4.87204 32.3682C5.63902 30.8629 6.86287 29.6391 8.36816 28.8721" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/></svg>`,
    selectIcon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 20C27.866 20 31 16.866 31 13C31 9.13401 27.866 6 24 6C20.134 6 17 9.13401 17 13C17 16.866 20.134 20 24 20Z" fill="#f15a29" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M12 7.25488C10.1865 8.51983 9 10.6214 9 13.0002C9 15.5465 10.3596 17.7753 12.3924 19.0002" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M36 7.25488C37.8135 8.51983 39 10.6214 39 13.0002C39 15.3789 37.8135 17.4806 36 18.7455" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M12 40V42H36V40C36 36.2725 36 34.4087 35.391 32.9385C34.5791 30.9783 33.0217 29.4209 31.0615 28.609C29.5913 28 27.7275 28 24 28C20.2725 28 18.4087 28 16.9385 28.609C14.9783 29.4209 13.4209 30.9783 12.609 32.9385C12 34.4087 12 36.2725 12 40Z" fill="#f15a29" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M43.9999 42.0001V40.8001C43.9999 36.3197 43.9999 34.0795 43.128 32.3682C42.361 30.8629 41.1371 29.6391 39.6318 28.8721" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M4.00009 42.0001V40.8001C4.00009 36.3197 4.00009 34.0795 4.87204 32.3682C5.63902 30.8629 6.86287 29.6391 8.36816 28.8721" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/></svg>`,
  },
  {
    id: 4,
    title: "文档资料",
    icon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="22" cy="40" r="4" fill="none" stroke="#fff" stroke-width="4"/><circle cx="26" cy="8" r="4" fill="none" stroke="#fff" stroke-width="4"/><circle cx="36" cy="24" r="4" fill="none" stroke="#fff" stroke-width="4"/><circle cx="12" cy="24" r="4" fill="none" stroke="#fff" stroke-width="4"/><path d="M32 24L16 24" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M23 11L15 21" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M32.9998 27L24.9987 37" stroke="#fff" stroke-width="4"/></svg>`,
    selectIcon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="22" cy="40" r="4" fill="#f15a29" stroke="#f15a29" stroke-width="4"/><circle cx="26" cy="8" r="4" fill="#f15a29" stroke="#f15a29" stroke-width="4"/><circle cx="36" cy="24" r="4" fill="#f15a29" stroke="#f15a29" stroke-width="4"/><circle cx="12" cy="24" r="4" fill="#f15a29" stroke="#f15a29" stroke-width="4"/><path d="M32 24L16 24" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M23 11L15 21" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M32.9998 27L24.9987 37" stroke="#f15a29" stroke-width="4"/></svg>`,
  },
  {
    id: 5,
    title: "随心随笔",
    icon: `<svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 44C35.0457 44 44 35.0457 44 24C44 24 33.5 27 27 20C20.5 13 24 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="none" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M44 24L24 4" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/></svg>`,
    selectIcon: `<svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 44C35.0457 44 44 35.0457 44 24C44 24 33.5 27 27 20C20.5 13 24 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#f15a29" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/><path d="M44 24L24 4" stroke="#f15a29" stroke-width="4" stroke-linecap="round" stroke-linejoin="bevel"/></svg>`,
  },
  {
    id: 6,
    title: "系统设置",
    icon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.0003 41L44 24L34.0003 7H14.0002L4 24L14.0002 41H34.0003Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="bevel"/><path d="M24 29C26.7614 29 29 26.7614 29 24C29 21.2386 26.7614 19 24 19C21.2386 19 19 21.2386 19 24C19 26.7614 21.2386 29 24 29Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="bevel"/></svg>`,
    selectIcon: `<svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.0003 41L44 24L34.0003 7H14.0002L4 24L14.0002 41H34.0003Z" fill="#f15a29" stroke="#f15a29" stroke-width="4" stroke-linejoin="bevel"/><path d="M24 29C26.7614 29 29 26.7614 29 24C29 21.2386 26.7614 19 24 19C21.2386 19 19 21.2386 19 24C19 26.7614 21.2386 29 24 29Z" fill="#FFF" stroke="#FFF" stroke-width="4" stroke-linejoin="bevel"/></svg>`,
  },
];

export default function Home() {
  //导航激活项
  const [option, setOption] = useState(1);
  const [optionTitle, setOptionTitle] = useState("首页数据");
  const navigate = useNavigate();
  const isMounted = useRef(false); // 用于跟踪组件是否已经加载过一次

  const changeNav = (index) => {
    if (index === option) {
      return;
    }
    if (index > navList.length) {
      return;
    }
    setOption(index);
    setOptionTitle(navList[index - 1].title);
    switch (index) {
      case 1:
        navigate("/home", { replace: true });
        break;
      case 2:
        navigate("/home/blogs", { replace: true });
        break;
      case 3:
        navigate("/home/users", { replace: true });
        break;
      case 4:
        navigate("/home/site", { replace: true });
        break;
      case 5:
        navigate("/home/essay", { replace: true });
        break;
      case 6:
        navigate("/home/settings", { replace: true });
        break;
      default:
        break;
    }
  };

  const navItem = navList.map((item) => (
    <div
      className={`home-nav-option ${
        option === item.id ? "home-nav-option-active" : ""
      }`}
      key={item.id}
      onClick={() => {
        changeNav(item.id);
      }}
    >
      <div
        className="home-nav-icon"
        dangerouslySetInnerHTML={
          option === item.id
            ? { __html: item.selectIcon }
            : { __html: item.icon }
        }
      ></div>
      {item.title}
    </div>
  ));

  // 导航重定向组件
  const NavigateToPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
      // 只在组件第一次加载时执行
      if (!isMounted.current) {
        isMounted.current = true;
        if (!hasCookie("AdminLogin")) {
          navigate("/login", { replace: true });
        } else {
          const worker = new Worker(
            new URL("../../worker/worker.js", import.meta.url)
          );
          worker.postMessage(getCookie("AdminLogin"));
          //等待吗工作区回复消息
          worker.onmessage = (res) => {
            res = res.data;
            if (res.error != null && res.error === 0) {
              if (res.data && res.data.token) {
                setCookie("AdminLogin", res.data.token, 1);
                return;
              }
            }
            // TODO 不符合条件则要退出登录
          };
        }
      }
    }, [navigate]);

    return null; // 不需要渲染任何内容
  };

  return (
    <div className="home">
      <NavigateToPage />
      <Head />
      <div className="home-container">
        <div className="home-container-left">{navItem}</div>
        <div className="home-container-right">
          <div className="home-container-title">{optionTitle}</div>
          <div className="home-container-main">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
