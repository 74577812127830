import CryptoJS from "crypto-js";

//偏移量
const offset =
  "D27J26a71tRj3DrWWDcNfenpmWYYiiCYsKC7NBP7bpprN8PFQwP7yKH8skJKydHyzQYXDtnKbB5K3jX4x2SJxxNwkAPBRBhW2tQsWJmmENw9wymMBDjdyr5ZQkpr9fnj";

function stringToSha512(txt) {
  try {
    // 计算 SHA-512 哈希
    const hash = CryptoJS.SHA512(txt);
    // 将哈希转换为十六进制字符串
    return hash.toString(CryptoJS.enc.Hex);
  } catch (error) {
    // console.error('Hashing failed:', error);
    return null; // 更明确的返回值
  }
}

function stringToSha512AndOffset(txt) {
  return stringToSha512(offset + txt);
}

function concatenateData(data) {
  // 获取数据的键并按字母顺序排序
  const sortedKeys = Object.keys(data).sort();
  // 根据排序后的键拼接值
  return sortedKeys.map((key) => data[key]).join("");
}

export { stringToSha512, stringToSha512AndOffset, concatenateData };
