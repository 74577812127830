import React from "react";
import ReactDOM from "react-dom/client";
//导入路由
import { RouterProvider } from "react-router-dom";
import "./index.css";
import routes from "./routes/routes";

const App = () => {
  return (
    <React.StrictMode>
      <RouterProvider router={routes}></RouterProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
