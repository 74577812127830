import "./head.css";

export default function Head() {
  return (
    <div className="head">
      <div className="head-left" title="码界轩">
        <img
          src="https://api.chenjiabao.com/public/upload/images/1/favicon.ico"
          alt=""
          width="27"
          height="27"
        />
        <p className="head-left-title">码界轩-后台管理平台</p>
      </div>
      <div className="head-centre"></div>
      <div className="head-end"></div>
    </div>
  );
}
