import classNames from "classnames";
import "./index.css";

export default function JXT_BUTTON({
  type = "submit",
  plain = false,
  disabled = false,
  style,
  onClick = function () {},
  children,
}) {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  const buttonClass = classNames("JXT-Button", {
    "JXT-Button_un": disabled,
    "JXT-Button-Plain": plain,
    "JXT-Button-Plain-un": !plain,
  });

  return (
    <button
      className={buttonClass}
      onClick={handleClick}
      disabled={disabled}
      style={style}
      type={type}
    >
      {children}
    </button>
  );
}
