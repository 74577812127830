import { useState, useRef } from "react";
import "./login.css";
import JXT_BUTTON from "../../components/JXT-Button";
import {
  isValidEmail,
  isValidPassword,
  isValidNumber,
} from "../../utils/string";
import JXT_TOAST from "../../components/JXT-Toast";
import request from "../../utils/axios";
import { stringToSha512AndOffset } from "../../utils/hash";
//导入路由
import { useNavigate } from "react-router-dom";
import {setCookie} from "../../utils/cookie";

export default function LargestContentfulPaint() {
  const navigate = useNavigate();

  const [accountTip, setAccountTip] = useState("");
  const [passwordTip, setPasswordTip] = useState("");
  const [accountInput, setAccountInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [codeInput, setCodeInput] = useState("");
  const [sendCodeTxt, setSendCodeTxt] = useState("发送验证码");
  const [sendCodeStatus, setSendCodeStatus] = useState(false);
  const toastRef = useRef(null);

  /**
   * 实时获取输入框的变化。注意setAccountInput为异步操作
   * @param {*} event
   */
  function changeAccountInput(event) {
    setAccountInput(event.target.value);
    if (event.target.value === "") {
      setAccountTip("*账号为必填项");
    } else {
      setAccountTip("");
    }
  }
  function changePasswordInput(event) {
    setPasswordInput(event.target.value);
    if (event.target.value === "") {
      setPasswordTip("*密码为必填项");
    } else {
      setPasswordTip("");
    }
  }
  function changeCodeInput(event) {
    setCodeInput(event.target.value);
  }

  /**
   * 发送验证码点击事件
   */
  function sendCodeClick() {
    if (!isValidEmail(accountInput)) {
      toastRef.current.showToast({
        value: "请输入正确的电子邮件地址！",
        icon: "warning",
      });
      return;
    }
    setSendCodeTxt("请稍候");
    setSendCodeStatus(true);
    request({
      url: "/login/sendCode",
      data: {
        mail: accountInput,
      },
      onSuccess(res) {
        if (res.error === 0) {
          let seconds = 120; // 记录已执行的秒数
          // 每秒执行一次的定时器
          const intervalId = setInterval(() => {
            if (seconds > 0) {
              // 检查是否达到 120 秒
              seconds--;
              setSendCodeTxt("请稍候(" + seconds + "秒)");
            } else {
              clearInterval(intervalId); // 达到 120 秒后停止定时器
              setSendCodeTxt("发送验证码");
              setSendCodeStatus(false);
            }
          }, 1000); // 1000 毫秒 = 1 秒
        } else {
          toastRef.current.showToast({
            value: res.message,
            icon: "warning",
          });
          setSendCodeTxt("发送验证码");
          setSendCodeStatus(false);
        }
      },
      onFail(err) {
        toastRef.current.showToast({
          value: "发生错误！",
          icon: "error",
        });
        setSendCodeTxt("发送验证码");
        setSendCodeStatus(false);
      },
    });
  }
  /**
   * 登录点击事件
   */
  function loginClick() {
    if (!isValidEmail(accountInput)) {
      toastRef.current.showToast({
        value: "请输入正确的电子邮件地址！",
        icon: "warning",
      });
      return;
    }
    if (
      !isValidPassword(passwordInput) ||
      passwordInput.length < 8 ||
      passwordInput.length > 16
    ) {
      toastRef.current.showToast({
        value: "密码格式不正确！",
        icon: "warning",
      });
      return;
    }
    if (!isValidNumber(codeInput) || codeInput.length !== 6) {
      toastRef.current.showToast({
        value: "验证码格式不正确！",
        icon: "warning",
      });
      return;
    }
    toastRef.current.showToast({
      value: "请稍候···",
      icon: "loading",
    });
    //发起登录请求
    request({
      url: "/login/login",
      data: {
        mail: accountInput,
        password: stringToSha512AndOffset(passwordInput),
        code: codeInput,
      },
      onSuccess(res) {
        //隐藏加载动画
        toastRef.current.hideToast();
        console.log(res)
        if (res.error === 0) {
          //登录成功
          setCookie("AdminLogin",res.data.token,1);
          navigate("/", { replace: true });
        } else {
          toastRef.current.showToast({
            value: res.message,
            icon: "warning",
          });
        }
      },
      onFail(err) {
        //隐藏加载动画
        toastRef.current.hideToast();
        toastRef.current.showToast({
          value: "发生错误！",
          icon: "error",
        });
      },
      complete() {
      },
    });
  }

  return (
    <div className="login">
      <JXT_TOAST ref={toastRef} />
      <div className="login-content">
        <div className="login-item">
          <div className="login-title">码界轩博客管理系统</div>
          <div className="login-version">1.0</div>
        </div>
        <form className="input-form">
          <div className="input-container">
            <input
              type="text"
              placeholder="请输入管理员账号"
              autocomplete="new-username"
              value={accountInput}
              onChange={changeAccountInput}
              minLength={50}
            />
          </div>
          <div className="input-tip">{accountTip}</div>
          <div className="input-container">
            <input
              type="password"
              placeholder="请输入8~16位密码"
              autocomplete="new-password"
              value={passwordInput}
              onChange={changePasswordInput}
              minLength={8}
              maxLength={16}
            />
          </div>
          <div className="input-tip"></div>
          <div className="input-container">
            <input
              type="text"
              placeholder="6位验证码"
              autocomplete="new-code"
              style={{ width: "50%" }}
              value={codeInput}
              onChange={changeCodeInput}
              maxLength={6}
            />
            <JXT_BUTTON
              style={{
                width: "calc(50% - 10px)",
                marginLeft: "10px",
                lineHeight: "1.25rem",
                padding: "1rem",
              }}
              type="button"
              onClick={sendCodeClick}
              disabled={sendCodeStatus}
            >
              {sendCodeTxt}
            </JXT_BUTTON>
          </div>
          <div className="input-tip">{passwordTip}</div>
          <JXT_BUTTON
            type="button"
            style={{ padding: "15px 40px" }}
            onClick={loginClick}
          >
            登录
          </JXT_BUTTON>
        </form>
        <div className="login-copyright">
          Copyright © 2024 chenjiabao.com All Rights Reserved.
        </div>
      </div>
    </div>
  );
}
