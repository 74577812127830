const JXT_Configs = {
  serverUrl: "https://admin.chenjiabao.com",
  webUrl: "https://admin.chenjiabao.com",
  request: {
    requestTimerId: null, //请求计时器
    num: 0, //请求次数
    url: "https://api.admin.chenjiabao.com/server",
    token: "cIMunBff2x9jTQS1JiuzNelj6Ech8oU4",
  },
};

//开发环境
if (process.env.NODE_ENV === "development") {
  JXT_Configs.serverUrl = "http://127.0.0.1:8000";
  JXT_Configs.webUrl = "http://localhost:3000";
  JXT_Configs.request.url = "http://127.0.0.1:8000/server";
}

export { JXT_Configs };
